import React, { useState } from 'react';
import { Row, Col, Layout } from 'antd';
const { Content } = Layout;

import { Button, Typography, Space } from 'antd';
import '../styles/shopping-summary.scss';
import CardStatus from '../components/card-status/card-status';
import HeaderComponent from '../components/header/header';

const { Text } = Typography;

const ShoppingSummary = () => {

  const [filterActive, setFilterActive] = useState(true);
  const [filterCompleted, setFilterCompleted] = useState(true);

  return (
    <Layout className="site-layout-background">
      <HeaderComponent title="Resumen de compras" goTo="/home"/>
      <Content>
        <Row>
          <Col span={24}>
            <Text className="text-primary" >Listado de compras nueva y anteriores</Text>
            <br/><br/>
            <Row>
              <Space>
                <Button shape='round' onClick={() => setFilterActive(!filterActive)}>
                  <Text>ACTIVOS</Text>
                </Button>
                <Button shape='round' onClick={() => setFilterCompleted(!filterCompleted)}>
                  <Text>FINALIZADO</Text>
                </Button>
              </Space>
            </Row>
            <br/>
          </Col>
        </Row>
        <Row>
          {filterActive ?
            <Col span={24}>
              <Text className="text-primary">Seguros activos</Text>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <a href='/order-details'>
                    <CardStatus
                      title="Jan 27, 2020 - 1:45 PM"
                      description="De Loja, Terminal terrestre A Cuenca"
                      status="ACTIVO"
                      goTo="/home"
                      typeButton="primary"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            : ''}
        </Row>
        <Row>
          {filterCompleted ?
            <Col span={24}>
              <Text className="text-primary">Seguros anteriores</Text>
              <Row>
                <Col span={24}>
                  <CardStatus
                    title="Jan 27, 2020 - 1:45 PM"
                    description="DE Loja, Terminal terrestre A Cuenca"
                    status="FINALIZADO"
                    goTo="/"
                    typeButton="dashed"
                  />
                </Col>
              </Row>
            </Col>
            : ''}
        </Row>
      </Content>
    </Layout>
  );
};

export default ShoppingSummary;
